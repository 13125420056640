<template>
  <div class="confirm-email">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 text-center">
        <h1 class="page-title">Inform your coupon</h1>
        
        <div>
          <b-form autocomplete="off" @submit.stop.prevent="onFormSubmit">
            <div class="row justify-content-center">
              <div class="col-12 col-md-6">
                <b-form-group class="custom">
                  <b-form-input v-model="form.code_coupon" trim placeholder="Coupon" />
                </b-form-group>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 col-md-6">
                <b-btn variant="primary" type="submit" block>
                  CHECK COUPON
                </b-btn>
              </div>
            </div>
          </b-form>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "@/mixin/toast";

import CouponService from "@/services/coupon.service";

export default {
  name: 'Coupon',
  metaInfo: {
    title: 'Inform your coupon',
  },
  mixins: [Toast],
  data() {
    return {
      form: {
        code_coupon: ''
      },
      confirmation_token: '',
      message: '',
      resend: false
    }
  },
  mounted() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.confirmation_token = this.$route.params.confirmation_token;
      if(this.confirmation_token) {
        this.validateToken()
      }
    },
    async onFormSubmit () {
      try {
        const response = await CouponService.show(this.form.code_coupon)

        const product = response.data.product;

        let ticket = {
          id: new Date().getTime(),
          ticket_name: '',
          covidTest: false,
          shuttle_departure: '',
          shuttle_arrival: '',
          day: '',
          hour: '',
          options_day: [],
          options_hour: [],
          optionsDeparture: [],
          optionsArrival: [],
          optionsDay: [],
        }

        product.quantity = 1;
        product.tickets = [ticket];
        let total = product.price * product.quantity

        product.total = total;

        this.$store.commit('updateCart', [product]);
        this.$store.commit('updateCoupon', {
          code: this.form.code_coupon,
          valid: response.data.valid,
          invalid: response.data.invalid,
          discount_amount: response.data.discount_amount,
          id: response.data.id,
        });

        this.$router.push('/cart/review')
      } catch (error) {
        this.showToast("danger", "Error", 'Coupon invalid');
      }
    },
  }
}
</script>

<style lang="scss">
.confirm-email {
  .page-title {
    margin: 6rem 0 55px;
    font-size: 47px;
    font-weight: 700;
  }

  h3 {
    font-weight: normal;
    font-size: 29px;
    margin-bottom: 20px;
  }

  svg {
    margin-bottom: 40px;
  }

  p {
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 40px;
  }

  a {
    font-size: 18px;
  }
}
</style>
