<template>
  <div class="container">
    <div class="row col-md-12">
      <h1>Community</h1>
    </div>

    <div class="row simple_discussion">
      <div class="col-md-3 mb-3">
        <div class="card card-body">
          <a class="btn btn-outline-primary btn-block" href="/forum/threads/new"
            >Ask A Question</a
          >
          <hr />

          <div class="forum-thread-filters">
            <h5>
              <strong> Filters </strong>
            </h5>
            <div>
              <a href="/forum/threads">
                <i class="fa-fw fas fa-bars"></i>
                All Threads
              </a>
            </div>
            <div>
              <a href="/forum/threads/answered">
                <i class="fa-fw fas fa-check"></i>
                Answered
              </a>
            </div>
            <div>
              <a href="/forum/threads/unanswered">
                <i class="fa-fw fas fa-question"></i>
                Unanswered
              </a>
            </div>
          </div>

          <hr />

          <div class="forum-thread-filters">
            <h6>
              <strong> By Category </strong>
            </h6>
            <div>
              <a href="/forum/threads"
                ><i class="fa-fw fas fa-circle"></i> All</a
              >
            </div>
            <div>
              <a href="/forum/threads/category/feedback">
                <i style="color: #16bc9c" class="fa-fw fas fa-circle"></i>
                Feedback
              </a>
            </div>
            <div>
              <a href="/forum/threads/category/general">
                <i style="color: #4ea1d3" class="fa-fw fas fa-circle"></i>
                General
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-9 mb-3">
        <div class="card card-body">
          <h1>Start A Discussion</h1>

          <div class="forum_post">
            <form
              class="new_forum_thread"
              autocomplete="off" @submit.stop.prevent="handleSubmit"
            >
              <input
                type="hidden"
                name="authenticity_token"
                value="GAaWcTw9Y_JQ7__0mEaShA3Ujd7Qnh1qyFC2CLkVxryawzbRC56gKUaaeQ_v7gzSmkVO446rPjWcfY9X67EtyQ"
              />

              <div class="form-group">
                <label for="forum_thread_forum_category_id"
                  >Choose a Category</label
                >
                <select
                  autofocus="autofocus"
                  class="form-control"
                  v-model="form.forum_category_id"
                >
                  <option value="">Pick a category...</option>
                  <option value="2">Feedback</option>
                  <option value="1">General</option>
                </select>
              </div>

              <div class="form-group">
                <label for="forum_thread_title">Title</label>
                <input
                  placeholder="How do I...?"
                  class="form-control"
                  type="text"
                  v-model="form.title"
                />
              </div>

              <div class="form-group">
                <label for="forum_thread_forum_posts_attributes_0_body"
                  >What do you need help with?</label
                >
                <textarea
                  placeholder="Add A Comment"
                  rows="10"
                  class="form-control simplemde"
                  data-behavior="comment-body"
                  v-model="form.forum_posts_attributes[0].body"
                  id="forum_thread_forum_posts_attributes_0_body"
                ></textarea>
              </div>

              <div class="form-group text-right">
                <button
                  name="button"
                  type="submit"
                  class="btn btn-primary"
                  data-disable-with="<i class='fa fa-spinner fa-spin'></i> Saving"
                >
                  Ask Your Question
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="whole-footer text-center">© 2021 WHOLE Festival GmbH</div>
  </div>
</template>

<script>
import ForumService from '@/services/forum.service'

export default {
  name: "ThreadNew",
  data: function () {
    return {
      form: {
        forum_category_id: "",
        title: "",
        forum_posts_attributes: [{
          body: ""
        }]
      },
    };
  },
  methods: {
    async handleSubmit() {
      console.log(this.form)

      let response = await ForumService.create({
          forum_thread: this.form,
        });
      
      console.log(response)
    },
  },
};
</script>

<style lang="scss">
  .card {
    background: var(--whole-bg-lighter);
  }
</style>