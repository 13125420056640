<template>
  <div class="data-protection-container">
    <h3 class="d-flex">Impressum gemäß §5 TMG</h3>

    <p>
      WHOLE GmbH <br />
      Schönhauser Allee 167a <br />
      10435 Berlin <br />
    </p>

    <p>
      Geschäftsführer Raquel Fedato Pogoda, Giacomo Garavelloni, Cristian
      Pereira Phillips, Danilo Rosato
    </p>

    <p>
      Registergericht: Amtsgericht Berlin-Charlottenburg <br />
      Register Nr.: HRB 206753 B
    </p>

    <p>
      Telefon: +49 30 83217850 <br />
      E-Mail: info@wholefestival.com
    </p>
  </div>
</template>

<script>
export default {
  name: "Impressum",
};
</script>

<style lang="scss" scoped>
.data-protection-container {
  h1 {
    margin: 25px 0px;
  }

  h3 {
    margin-bottom: 25px;
  }

  h4 {
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 15px;
  }
}
</style>