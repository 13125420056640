<template>
  <div class="user-orders">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <div class="profile-tabs">
          <router-link to="/user/orders">My orders</router-link>
          <router-link to="/user/profile">Profile</router-link>
          <a href="javascript:void(0)" @click="logout()">Logout</a>
        </div>

        <p class="my-2 text-center">If you experienced any problems with the purchase, please contact us here ticketing@wholefestival.com with the issue and we will take action.</p>
        <b-form @submit.stop.prevent="onSubmit">
          <div class="order-listing">
            <div class="order-item" v-for="order in orders" :key="order.id">
              <div class="order-item" v-for="item in order.order_items" :key="item.id">
                <div class="row">
                  <div class="col-10">
                    <h3 class="item-title">{{ item.product.name }}</h3>
                    <p class="item-description">{{ item.product.description }}</p>
                    <b-form-group label="Name / Chosen name on Ticket *" class="custom" v-if="isTicket(item) || isShuttle(item) || isCovid(item) || isParking(item)">
                      <b-form-input v-if="item.editing" v-model="item.ticket_name" required trim v-on:keydown.enter="changeTicketName(item)"></b-form-input>
                      <span v-else class="d-block align-items-center">
                        <PenInactiveIcon @click="item.editing = true" v-if="!item.ticket_name_changed" />
                        <strong class="d-inline-block ml-2">
                          {{ item.ticket_name || 'unnamed' }}
                          <p v-if="item.ticket_name_changed">Original name: {{ item.ticket_name_original }} changed at: {{ item.ticket_name_changed_at }} </p>
                        </strong>
                        <p v-if="isShuttle(item)">
                          {{ item.shuttle_details }}
                        </p>
                      </span>
                    </b-form-group>
                    <div class="actions" v-if="item.editing">
                      <b-btn @click="item.editing = false" variant="outline-primary">cancel</b-btn>
                      <b-btn @click="changeTicketName(item)" variant="primary">change</b-btn>
                    </div>
                  </div>
                  <div v-if="item.ticket_pdf_url" class="col-2 text-right">
                    <b-overlay
                      :show="item.busy"
                      rounded
                      opacity="0.6"
                      spinner-small
                      spinner-variant="primary"
                      class="d-flex flex-column align-items-center"
                    >
                      <DownloadIcon @click="printTicket(item)" v-if="!order.cancelled" :disabled="item.busy" />
                    </b-overlay>

                    <b-overlay
                      :show="item.busy"
                      rounded
                      opacity="0.6"
                      spinner-small
                      spinner-variant="primary"
                      class="d-flex flex-column align-items-center"
                    >
                      <MailIcon @click="sendTicket(item)" v-if="!order.cancelled" :disabled="item.busy" />
                    </b-overlay>
                  </div>

                </div>
                <div v-if="!item.ticket_pdf_url" class="row">
                  <div class="col-12">
                    <p class="mb-0 text-center">we're <strong>preparing your tickets</strong> - they'll be sent by email</p>
                  </div>
                </div>

                <hr class="bg-secondary">
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <small>payment by {{order.payment_method}}</small>
                  <b-btn @click="cancel(order)" variant="link" v-if="false" :disabled="order.cancelled">Cancel order</b-btn>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "@/mixin/toast"
import DownloadIcon from "@/assets/icons/download.svg";
import MailIcon from "@/assets/icons/icon-mail.svg";
import PenInactiveIcon from "@/assets/icons/pen-inactive.svg";
import OrderService from "@/services/order.service";
export default {
  name: 'UserOrders',
  metaInfo: {
    title: 'My Orders',
  },
  mixins: [ Toast ],
  components: {
    DownloadIcon,
    PenInactiveIcon,
    MailIcon
  },
  mounted() {
    console.log("UserOrders")
    this.getOrders();
  },
  data() {
    return {
      orders: [],
      busy: false
    }
  },
  methods: {
    logout() {
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "",
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch("AuthModule/logout", this.form);

            this.$router.push({
              name: "Home",
            });
          }
        });
    },
    getOrders() {
      OrderService.getAll().then((result) => {
        if(result){
          this.orders = result.data;
        }
      }, (error) => {
        console.log(error)
      })
    },
    printTicket(item) {
      item.busy = true;
      if (item.ticket_pdf_url) {
        window.open(item.ticket_pdf_url);
      } else {
        this.showToast('danger', 'Error', "we're preparing your tickets - they'll be sent by email");
      }
      item.busy = false;
    },
    sendTicket(item) {
      item.busy = true;
      OrderService.send_ticket(item).then((result) => {
        item.busy = false;
        if(result.data){
          this.showToast('success', 'Sended with Success', 'Check your email');
        } else {
          this.showToast('danger', 'Error', result.data.errors[0]);
        }
      }, (error) => {
        item.busy = false;
        console.log(error);
      })
    },
    changeTicketName(item) {
      item.editing = false;
      this.$bvModal.msgBoxConfirm("Changing ticket name will automatically delete your ticket and create a new one. For safety reasons, you can only change your ticket name once.", {
        title: "Change ticket name" ,
        okTitle: "confirm and Change ticket name",
        cancelVariant: "outline-primary"
      }).then((value) => {
        if(value) {
          item.busy = true;
          OrderService.updateOrderItem(item).then((result) => {
            item.busy = false;
            if(result.data.status){
              this.$bvToast.show(`toastTicketName`, {
                noAutoHide: false,
                appendToast: true,
                noCloseButton: false,
              })
              item.ticket_name_changed = true;
              item.ticket_name_changed_at = result.data.ticket_name_changed_at;
              item.ticket_name_original = result.data.ticket_name_original;
              this.getOrders()
            } else {
              this.showToast('danger', 'Error', result.data.errors[0]);
            }
          }, (error) => {
            item.busy = false;
            console.log(error);
          })
        }
      })
    },
    cancel(item) {
      this.$bvModal.msgBoxConfirm("Cancel order", {
        title: "Cancel order" ,
        okTitle: "confirm and cancel order",
        cancelVariant: "outline-primary"
      }).then((value) => {
        if(value) {
          item.cancelled = true;

          OrderService.cancel(item).then((result) => {
            item.busy = false;
            if(result.data.status){
              this.showToast('success', 'Canceled with Success', 'Check your email for details');
              this.orders.splice(this.orders.findIndex(product => product.id == item.id),1)
            } else {
              this.showToast('danger', 'Error', result.data.errors[0]);
            }
          }, (error) => {
            item.busy = false;
            console.log(error);
          })
        }
      })
    },
    isTicket(item){
      return item.product.product_type.includes('ticket');
    },
    isShuttle(item){
      return item.product.product_type == 'product_type_shuttle';
    },
    isCovid(item){
      return item.product.product_type == 'product_type_covid_test';
    },
    isParking(item){
      return item.product.product_type == 'product_type_parking';
    },
    isCovidTest(item){
      return item.product.product_type == 'product_type_covid_test';
    }
  }
}
</script>

<style lang="scss">
.user-orders {
  .page-title {
    margin: 6rem 0 3rem;
    font-size: 47px;
    font-weight: 700;
  }

  h3 {
    font-size: 29px;
    font-weight: 700;
  }

  .order-listing {
    margin-top: 60px;
    .order-item {

      &:not(:first-child) {
        margin-top: 7px;
      }

      background: var(--whole-bg-lighter);;
      padding: 20px 30px;

      .actions {
        .btn {
          text-transform: uppercase;
          font-weight: 500;
          font-size: 18px;
          border-radius: 0;
          &:not(:last-child) {
            margin-right: 0.5rem;
          }
        }
      }
      .cancel {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .text-right {
        .b-overlay-wrap {
          svg {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
</style>
