<template>
  <div class="data-protection-container">
    <h1 class="d-flex justify-content-center">GENERAL TERMS AND CONDITIONS</h1>

    <p>
      Note: As far as this contract makes reference to personal relations in the
      masculine form, this wording shall address both male and female as well as
      diverse persons.
    </p>

    <h3>
      1. CONTRACTING PARTIES AND SCOPE OF THESE GENERAL TERMS AND CONDITIONS
    </h3>

    <p>
      1.1. For the business relations between WHOLE GmbH, Schönhauser Allee 167a
      in 10435 Berlin, represented by the managing directors Christian Phillips,
      Danilo Rosato, Giacomo Garavelloni, Giovanni Turco, Jacob Meehan and
      Raquel Fedato, (hereinafter referred to as "SELLER") and the Customer,
      these General Terms and Conditions apply exclusively in the version valid
      at the time of the order, unless otherwise agreed. Deviating terms and
      conditions of the Customer will not be accepted unless the Seller
      expressly agrees to their applicability. <br />
      1.2. According to § 13 BGB (German Civil Code), consumer is any natural
      person who enters a legal transaction for a purpose that cannot be
      attributed to his commercial or independent professional activity. <br />
      1.3. The right of withdrawal in these GTC does not apply as part of
      business relations between SELLER and entrepreneurs according to § 14 BGB.
      We recommend the timely exercise of the right of withdrawal to Customers
      who are not sure whether they are consumers or entrepreneurs. If the
      subsequent legal examination shows that the customer is an entrepreneur
      within the meaning of § 14 BGB, sentence 1 shall apply.
    </p>

    <h3>2. OBLIGATIONS OF THE CUSTOMER</h3>

    <p>
      2.1 When registering/ordering, Customer is obliged to provide true
      information. Customer must inform SELLER immediately of any changes to
      data that are important for the business relationship (e.g., name,
      address, e-mail address), at the latest before the next order. If customer
      delivers incorrect data, SELLER is entitled to withdraw from contracts
      already concluded and to block his online store for this customer.
    </p>

    <p>
      2.2 When registering in SELLER ́s online store Customer chooses a password
      for future access. Customer undertakes to treat this password
      confidentially and not to make it accessible to unauthorized third
      parties. SELLER does not assume any liability for misuse of the password
      caused by Customer.
    </p>

    <p>
      2.3 Order processing and contacting usually take place via e-mail and
      automated order processing. Customer must make sure that the e-mail
      address indicated by him for order processing is correct, so that e-mails
      sent by SELLER can be received under this address. In particular, when
      using SPAM filters, customer must ensure that all e-mails sent by SELLER
      or third parties commissioned by SELLER to process the order can be
      delivered.
    </p>

    <h4>
      3. CONCLUSION OF CONTRACT/TECHNICAL PROCEDURE FOR CONCLUSION OF CONTRACT/
      CONTRACT LANGUAGE
    </h4>

    <p>
      3.1 The presentation of tickets / goods / service offers does not
      constitute a binding contractual offer. By sending a chargeable order via
      internet, fax, telephone or e-mail, the customer makes a binding offer.
      SELLER reserves the right to decide whether to accept the offer. The
      acceptance is made by order confirmation or by sending the goods. If the
      customer does not receive an order confirmation or delivery within two
      weeks, he is no longer bound to his order.
      <br />3.2. Information on technical steps for contract conclusion
      <br />3.2.1. On the product offer page Customer can add goods to the
      shopping cart by pressing the button "Add to cart" or a corresponding
      graphic symbol. After Customer has placed all products that he wants to
      purchase in the shopping cart, he will be able to initiate the ordering
      process by pressing the "Checkout" button. <br />3.2.2. In the next step,
      Customer can log in for the first time by entering and registering his
      personal data or log in with his existing access data by entering his
      e-mail address and password and then pressing the "Log in" button.
      <br />3.2.3 Alternatively, an order is also possible without creating a
      customer account. In this case, Customer must provide billing and delivery
      addresses as well as customers valid e-mail address. <br />3.2.4. An
      overview opens showing billing and delivery addresses and a field for
      entering delivery remarks. In the field below customer can select his
      payment method. After the selection, the General Terms and Conditions are
      displayed and must be accepted by customer by means of an acceptance
      button. Below this, an overview of the selected products is shown to
      Customer. Before the binding submission of the order that he wants to
      purchase, Customer can continuously correct his entries using the usual
      keyboard and mouse functions. For this purpose, the browser function
      "Back" can be selected to return to the shopping cart, where the selected
      goods and the quantities can be changed and corrected. At the end of the
      page, the customer clicks the button "Order now to be charged" or a button
      clearly labeled in accordance with §312j para. 3 BGB. Subsequently, a page
      opens which confirms Customer's order. <br />3.3 The acceptance by SELLER
      takes place in accordance with paragraph 3.1. <br />3.4 The text of the
      contract shall be made available exclusively in the German and English
      languages.
    </p>

    <h4>4. PRICES</h4>

    <p>
      Unless otherwise resulting from SELLER's product description, the prices
      quoted are total prices including the statutory value added tax. Any
      additional delivery and shipping costs will be indicated separately in the
      respective product description.
    </p>

    <h4>5. DELIVERY OF GOODS, SHIPPING COSTS, RETURN COSTS</h4>

    <p>
      5.1 Tickets are delivered by e-mail as well as in SELLER's customer area
      as PDF file for printing.
      <br />The delivery of goods is made by shipping to the delivery address
      specified by Customer, unless otherwise agreed. <br />When processing the
      transaction, the e-mail address or delivery address specified in SELLER's
      order processing shall be decisive. <br />5.2 If the transport company
      returns the shipped goods to SELLER because delivery to the Customer was
      not possible, Customer shall bear the costs for unsuccessful shipment.
      <br />This does not apply if Customer effectively exercises his right of
      withdrawal, if he is not responsible for the circumstances that led to the
      impossibility of delivery, or if he was temporarily prevented from
      accepting the offered service, unless SELLER had given him reasonable
      advance notice of the service. <br />5.3 The risk of accidental loss and
      accidental deterioration of the goods sold shall be transferred to
      Customer only at the time the goods are handed over to Customer or to a
      person authorized to receive them. <br />Notwithstanding the foregoing,
      the risk of accidental loss and accidental deterioration of the goods sold
      shall be transferred to the Customer as soon as SELLER has delivered the
      goods to the forwarding agent, the carrier or any other person or
      institution designated to carry out the shipment, if the customer has
      commissioned the forwarding agent, the carrier or any other person or
      institution designated to carry out the shipment and the SELLER has not
      previously notified the customer of such person or institution. <br />5.4.
      SELLER shall exceptionally not be obliged to deliver the ordered goods if
      SELLER has duly ordered the goods but has not been supplied correctly or
      in time (congruent hedging transaction). The premise is that SELLER is not
      responsible for the lack of availability of the goods and that Customer
      has been informed immediately of this circumstance. In addition, SELLER
      must not have assumed the risk of procuring the ordered goods. In the case
      of corresponding unavailability of the goods, SELLER shall immediately
      refund to the Customer any payments already received. SELLER does not
      assume the risk of having to procure ordered goods (procurement risk).
      <br />This also applies to orders for goods that are only described in
      terms of their type and characteristics (generic goods). SELLER is only
      obliged to deliver goods from his stock of goods and the goods ordered by
      him from his suppliers.
    </p>

    <h4>6. RETENTION OF TITLE</h4>

    <p>
      SELLER retains ownership of the delivered goods until full payment of the
      purchase price owed.
    </p>

    <h4>7. PAYMENT</h4>

    <p>
      Various payment options are available for the Customer indicated in SELLER
      ́s online store.
    </p>

    <p>
      <strong>7.1 Payment by PayPal:</strong> When paying by means of a payment
      method offered by PayPal, payment is processed via the payment service
      provider PayPal (Europe) S.a.r.l. et Cie, S.C.A., 22-24 Boulevard Royal,
      L-2449 Luxembourg (hereinafter: "PayPal"), subject to the PayPal terms of
      use, available at
      https://www.paypal.com/de/webapps/mpp/ua/useragreement-full or - if the
      Customer does not have a PayPal account - subject to the terms and
      conditions for payments without PayPal account, available at
      https://www.paypal.com/de/webapps/mpp/ua/privacywax-full. <br /><strong
        >7.2 Payment by PayPal-Invoice:</strong
      >
      If the payment method "PayPal-Invoice" is selected, SELLER assigns his
      demand for payment to PayPal. Prior to acceptance of SELLER's declaration
      of assignment, PayPal shall perform a credit check using the transmitted
      customer data. SELLER reserves the right to deny the Customer the payment
      method "PayPal invoice" in case of a negative result of the credit check.
      If the payment method "PayPal-Invoice" is admitted by PayPal, Customer
      must pay the invoice amount to PayPal within 30 days from receipt of the
      goods, unless he is given a different payment term by PayPal. In this
      case, Customer can make a payment with effect of discharging the debt to
      PayPal only. However, even in the case of assignment of receivables,
      SELLER remains responsible for general customer inquiries, e.g., regarding
      the goods, delivery time, shipment, returns, complaints, revocation
      declarations and deliveries or credit notes. In addition to the above, for
      the use of the payment method "PayPal-Invoice" apply the General Terms and
      Conditions of Use, which can be viewed at
      https://www.paypal.com/de/webapps/mpp/ua/pui-terms. <br /><strong
        >7.3 Payment by PayPal direct debit:</strong
      >
      If the payment method "PayPal Direct Debit" is selected, PayPal shall
      collect the invoice amount from Customer's bank account after a SEPA
      direct debit mandate has been issued, but not before the expiry of the
      deadline for the pre-notification by order of SELLER. Pre-notification is
      any communication (e.g., invoice, policy, contract) to the customer
      announcing a debit by SEPA Direct Debit. If the direct debit is not
      honored due to insufficient funds in the account or due to the provision
      of incorrect bank details, or if the Customer objects to the direct debit
      even though he is not entitled to do so, the Customer shall bear the fees
      incurred by the chargeback of the respective credit institution if he is
      responsible for this. <br /><strong>7.4 Stripe Payments Europe:</strong>
      The processing of the payment methods credit card payment, GIROPAY,
      INSTANT TRANSFER and SEPA DIRECT DEBIT is carried out in cooperation with
      Stripe Payments Europe, Ltd, c/o A&l Goodbody, Ifsc,

      <br />North Wall Quay, Dublin 1, Ireland (hereinafter: "Stripe"), subject
      to the Stripe Terms of Use, available at https://stripe.com/de/terms, to
      which SELLER assigns his demand for payment. Stripe collects the invoice
      amount from the specified credit card account or bank account of Customer.
      In case of assignment, payment with debt discharging effect can be made to
      Stripe only. The credit card will be charged immediately after sending the
      customer's order in the online store or after 2 days by SEPA direct debit.
      Even if the payment method like CREDIT CARD PAYMENT, GIROPAY, INSTANT
      TRANSFER and SEPA DIRECT DEBIT is selected via Stripe, SELLER remains
      responsible for general customer inquiries, e.g., regarding the goods,
      delivery time, shipment, returns, complaints, revocation declarations and
      deliveries or credit notes. <br /><strong
        >7.5 Klarna PayNow Direct Debit:</strong
      >
      After a successful address and credit check during the ordering process,
      the Customer issues a SEPA direct debit mandate to the online provider
      Klarna when submitting the order. Klarna will inform Customer about the
      date of the account debit (so-called prenotification). Upon submission of
      the direct debit mandate, Klarna will request your bank to initiate the
      payment transaction. The payment transaction will be executed
      automatically and your account will be debited. The account will be
      debited after the goods have been shipped. For payment processing via
      Klarna, the terms and conditions and privacy policy of Klarna apply in
      addition to SELLER ́s terms and conditions. Further information Customer
      will receive in the ordering process.
    </p>

    <h4>8. RIGHT OF REVOCATION</h4>

    <p>
      <strong
        >8.1 Right of Revocation: <br />
        You have the right to revoke the contract within fourteen days without
        any reason. The revocation period is fourteen days from the date of
        conclusion of the contract. To exercise your right of revocation, you
        must send to us,</strong
      >
    </p>

    <p>
      <strong>
        WHOLE GmbH <br />
        Schönhauser Allee 167a <br />
        10435 Berlin <br />
      </strong>
    </p>

    <p>
      <strong>
        Tel. +49(0)30 – +49 30 83217850 <br />
        Fax +49(0)30 – __________ <br />
        contact@wholefestival.com <br />
      </strong>
    </p>

    <p>
      <strong>
        your decision to revoke the contract by means of a clear declaration
        (e.g., a letter sent by mail, fax or e-mail). You can also fill out and
        submit the standard withdrawal form or another clear declaration on our
        website electronically. If you make use of this option, we shall
        immediately send to you (e.g., by e-mail) a confirmation of receipt of
        such revocation. To comply with the revocation period, it is sufficient
        that you send the notification of the exercise of the right of
        revocation before the expiry of the revocation period.
      </strong>
    </p>

    <p><strong>Consequences of revocation:</strong></p>

    <p>
      <strong
        >If you revoke the contract, we shall reimburse to you all payments we
        have received from you, including delivery costs (with the exception of
        additional costs resulting from the fact that you have chosen a type of
        delivery other than the most favorable standard delivery offered by us),
        without undue delay and no later than within fourteen days from the day
        on which we received the notification of your revocation of the
        contract. For this repayment, we will use the same means of payment that
        you used for the original transaction, unless expressly agreed
        otherwise; <br />in no case you will be charged any fees because of this
        repayment. We may refuse repayment until we have received the goods back
        or until you have provided proof for having returned the goods,
        whichever is the earlier. You must return or hand over the goods to us
        without undue delay and in any case no later than within fourteen days
        from the day on which you notified us of the revocation of the contract.
        The deadline is met if you send the goods before the expiry of the
        period of fourteen days. <br />For a possible loss in value of the goods
        you only will be liable if this loss in value is due to a not suitable
        way of handling the goods for examination of the condition, properties
        and functioning of the goods.</strong
      >
    </p>

    <p><strong>- End of the cancellation policy instructions-</strong></p>

    <p>
      8.2 The right of revocation does not exist <br />8.2.1. in case of
      delivery of goods that can spoil quickly or whose expiration date would be
      quickly exceeded, <br />8.2.2. in case of delivery of sealed goods, which
      are not suitable for return for reasons of health protection or hygiene,
      if their seal has been removed after delivery. <br />8.3 Before returning
      the goods, you should call us at the phone number indicated on our website
      to announce the return. In this way, you will enable us to allocate the
      products as quickly as possible. <br />8.4 It should be noted that the
      modalities mentioned in the previous paragraphs are not a prerequisite for
      the effective exercise of the right of revocation.
    </p>

    <h4>9. WARRANTY AND LIABILITY</h4>

    <p>
      9.1 If the purchased good is defective, the provisions of the statutory
      liability for defects shall apply.
      <br />9.2 The limitation period is two years from delivery of the goods to
      the Customer. <br />9.3 Cases of liability for defects shall be settled
      directly with SELLER. Negotiations with independent representatives who
      are not directly employed by us do not constitute negotiations within the
      meaning of § 203 para.1 BGB. 9.4 If Customer recognizes a transport damage
      to the delivered goods/packaging already upon delivery, Customer is
      requested to have this damage confirmed in writing by the employee of the
      carrier upon receipt of the goods and to inform SELLER about this. Failure
      to provide written confirmation shall have no consequences for Customer's
      statutory warranty rights. The written confirmation of the carrier only
      supports the assertion of SELLER's claim against the carrier or the
      transport insurance. In case Customer takes photographs of the damage,
      Customer is asked to send these pictures to the SELLER. <br />9.5 SELLER
      shall be liable in accordance with the statutory provisions if the
      Customer asserts claims for damages based on intent or gross negligence of
      SELLER, SELLER ́s legal representatives or SELLER ́s vicarious agents. If
      SELLER is not charged of intentional or grossly negligent breach of
      contract, the liability for damages shall be limited to the foreseeable,
      typically occurring damage. In the event of slight negligence, SELLER
      shall not be liable for the breach of ancillary obligations with the
      exception of pre-contractual ancillary obligations. Otherwise, any
      liability of the Seller is excluded. <br />9.6 Liability for culpable
      injury to life, limb or health shall remain unaffected; this shall also
      apply to mandatory liability under the Product Liability Law and other
      mandatory statutory provisions.
    </p>

    <h3>10. EVENTS</h3>

    <p>
      10.1 Upon presentation of the ticket valid for the event, Customer gets
      the opportunity to participate in the event organized by SELLER. Each
      ticket is provided with a QR code, which is valid once and entitles
      one-time access to the event premises.
      <br />10.2 The Customer or the ticket holder is prohibited from bringing
      to the event dangerous items such as weapons of any kind, items suitable
      to be used as projectiles (especially bottles and cans), gas containers,
      pyrotechnic items (e.g., flares, fireworks or sparklers), backpacks and
      big bags larger than 21 x 30 cm or umbrellas. If the Customer violates
      this agreement, the SELLER as organizer of the event is entitled to
      prohibit the Customer from participating in the event and to expel the
      Customer from the premises. In this case, the Customer is not entitled to
      any claims against the Seller. <br />10.3 In case of adjournments of
      events, SELLER is entitled to declare original tickets of the relocated
      event as valid for the new, adjourned date of the event. A return of the
      tickets to the organizer or a reverse transaction of the ticket purchase
      due to the rescheduling is not possible in these cases unless the
      <br />appreciation of the rescheduled date is demonstrably unreasonable
      for the ticket holder. This shall not apply if the organizer is
      responsible for rescheduling of the event. <br />10.4 SELLER or third
      parties authorized by SELLER shall be entitled to make sound/photo and
      film recordings of any kind and manner (e.g., radio/television/streaming
      recordings etc.), in particular also those that recognizably and
      individually depict the Customer, and to exploit these without limitation
      regarding time, place and content in any form, also by authorized third
      parties, and particularly (but not conclusively) to reproduce,
      disseminate, make publicly accessible, broadcast etc. the recordings also
      by third parties. The consent is given expressly waiving any claim to
      remuneration.
    </p>

    <h3>11. FINAL PROVISIONS</h3>

    <p>
      11.1 Additions or amendments to this contract must be made in writing.
      This shall also apply to this clause governing the use of the written
      form.
      <br />11.2 To the extent permitted by law, the place of performance and
      jurisdiction for goods and services and all disputes arising between the
      parties shall be SELLER's registered office. In the case of end-consumers,
      the place of performance and jurisdiction shall be the domicile of the
      Customer. If the private end-consumer does not have a place of residence
      within the European Union, the place of jurisdiction shall be at the
      registered office of the SELLER. <br />In dealings with end-consumers
      within the European Union, the law of the end consumer's place of
      residence may also be applicable if it is mandatory under consumer law.
      <br />11.3 For consumers and for other persons with their habitual
      residence or domicile in a member state of the European Union (EU) or the
      European Economic Area (EEA) at the time of conclusion of the contract,
      German law shall apply to the exclusion of the UN Convention on Contracts
      for the International Sale of Goods. For consumers with habitual residence
      in a state that is neither a member of the EU nor the EEA, German law
      shall apply to the exclusion of the UN Convention on Contracts for the
      International Sale of Goods and German consumer protection law.
      Irrespective of this choice of law, for consumers within the EU also shall
      always applicable the mandatory consumer protection law of the country in
      which they have their habitual residence at the time of conclusion of the
      contract. <br />11.4 If one or more of the clauses of this contract are or
      become invalid in the course of time, the validity of the other contract
      clauses shall remain unaffected. The invalid clauses shall be replaced by
      statutory provisions. The statutory provisions shall also apply in the
      event of a regulatory gap.
    </p>

    <h4>12. ALTERNATIVE DISPUTE RESOLUTION</h4>

    <p>
      12.1 The EU Commission provides a platform for online dispute resolution
      on the Internet at the following link: http://ec.europa.eu/consumers/odr.
      This platform serves as a contact point for the out-of-court settlement of
      disputes arising from online purchase or service contracts involving a
      consumer.
      <br />12.2 SELLER is neither obligated nor willing to participate in a
      dispute resolution procedure before a consumer arbitration board.
    </p>

    <h1 class="d-flex justify-content-center">
      ALLGEMEINE GESCHÄFTSBEDINGUNGEN
    </h1>

    <p>
      Hinweis: Soweit in diesem Vertrag personenbezogene Beziehungen in
      männlicher Form erfolgen, sind sowohl männliche als auch weibliche und
      diverse Personen mit dieser Formulierung angesprochen.
    </p>

    <h3>1. VERTRAGSPARTNER UND GELTUNG DIESER AGB</h3>

    <p>
      1.1. Für die Geschäftsbeziehungen zwischen der WHOLE GmbH, Schönhauser
      Allee 167a in 10435 Berlin, vertreten durch die Geschäftsführer Christian
      Phillips, Danilo Rosato, Giacomo Garavelloni, Giovanni Turco, Jacob Meehan
      und Raquel Fedato, (nachfolgend „Verkäufer“) und dem Kunden gelten
      ausschließlich diese allgemeinen Geschäftsbedingungen in ihrer zum
      Zeitpunkt der Bestellung gültigen Fassung, sofern nichts anderes
      vereinbart wurde. Abweichende Bedingungen des Bestellers werden nicht
      anerkannt, es sei denn, der Verkäufer stimmt ihrer Geltung ausdrücklich
      zu.
      <br />
      1.2. Verbraucher ist nach § 13 BGB jede natürliche Person, die ein
      Rechtsgeschäft zu einem Zwecke abschließt, der weder ihrer gewerblichen
      noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden kann.
      <br />
      1.3. Das Widerrufsrecht in diesen AGB findet im Rahmen von
      Geschäftsbeziehungen zwischen dem Verkäufer und Unternehmern nach § 14 BGB
      keine Anwendung. Kunden, die sich nicht sicher sind, ob sie Verbraucher
      oder Unternehmer sind, empfehlen wir die fristgerechte Ausübung des
      Widerrufsrechts. Ergibt die anschließende rechtliche Prüfung, dass der
      Kunde Unternehmer im Sinne des § 14 BGB ist, gilt Satz 1.
    </p>

    <h3>2. PFLICHTEN DES KUNDEN</h3>

    <p>
      2.1. Der Kunde ist verpflichtet, bei seiner Registrierung/Bestellung
      wahrheitsgemäße Angaben zu machen. Änderungen, der für unsere
      Geschäftsbeziehung wichtigen Daten (z. B. Name, Adresse, E-Mail-Adresse),
      hat uns der Kunde unverzüglich, spätestens vor der nächsten Bestellung
      mitzuteilen. Werden uns falsche Daten mitgeteilt, sind wir zum Rücktritt
      von bereits geschlossenen Verträgen und zur Sperrung unseres Online-Shops
      für diesen Kunden berechtigt.
      <br />2.2. Kunden, die sich in unserem Online-Shop registrieren, wählen
      für den zukünftigen Zugang selbst ein Passwort. Der Kunde verpflichtet
      sich, dieses Passwort vertraulich zu behandeln und unbefugten Dritten
      nicht zugänglich zu machen. Für Missbrauch des Passwortes, den der Kunde
      verursacht hat, übernehmen wir keine Haftung. <br />2.3. Die
      Bestellabwicklung und Kontaktaufnahme finden in der Regel per E-Mail und
      automatisierter Bestellabwicklung statt. Der Kunde hat sicherzustellen,
      dass die von ihm zur Bestellabwicklung angegebene E-Mail-Adresse
      zutreffend ist, so dass unter dieser Adresse die vom Verkäufer versandten
      E-Mails empfangen werden können. Insbesondere hat der Kunde bei dem
      Einsatz von SPAM-Filtern sicherzustellen, dass alle vom Verkäufer oder von
      diesem mit der Bestellabwicklung beauftragten Dritten versandten E-Mails
      zugestellt werden können.
    </p>

    <h4>
      3. VERTRAGSSCHLUSS/TECHNISCHER ABLAUF ZUM VERTRAGSSCHLUSS/VERTRAGSSPRACHE
    </h4>

    <p>
      3.1. Die Darstellung der Tickets / Waren / Leistungsangebote stellt kein
      bindendes Vertragsangebot dar. Indem der Kunde per Internet, Fax, Telefon
      oder E-Mail eine kostenpflichtige Bestellung absendet, gibt er ein
      verbindliches Angebot ab. Der Verkäufer behält sich die freie Entscheidung
      über die Annahme des Angebots vor. Die Annahme erfolgt durch
      Auftragsbestätigung oder Zusendung der Ware. Sollte der Kunde binnen zwei
      Wochen keine Auftragsbestätigung oder Lieferung erhalten, ist der Kunde
      nicht mehr an seine Bestellung gebunden.
      <br />3.2. Information über die technischen Schritte zum Vertragsschluss
      <br />3.2.1. Der Kunde kann auf der Warenangebotsseite durch Drücken des
      Buttons „In den Warenkorb“ bzw. ein entsprechendes graphisches Symbol
      Waren in den Warenkorb legen. Nachdem der Kunde alle von ihm gewünschten
      Waren in den Warenkorb gelegt hat, kann er durch das Betätigen des Buttons
      „zur Kasse“ den Bestellprozess initiieren. <br />3.2.2. Der Kunde kann
      sich im nächsten Schritt erstmalig anmelden, indem er seine persönlichen
      Daten angibt und registriert oder mit seinen bereits vorhandenen
      Zugangsdaten anmeldet, indem er seine E-Mail Adresse und sein Passwort
      eingibt und anschließend den Button „Anmelden“ drückt. <br />3.2.3.
      Alternativ ist eine Bestellung auch ohne Anlegung eines Kunden-Accounts
      möglich. In diesem Fall muss der Kunde Angaben zur Rechnungs- und
      Lieferanschrift machen sowie seine E-Mail Adresse angeben. <br />3.2.4. Es
      öffnet sich eine Übersicht mit der Angabe der Rechnungs- und
      Lieferanschrift sowie einem Feld zur Eingabe von Lieferbemerkungen. Der
      Kunde kann in dem darunter befindlichen Feld seine Zahlungsmethode
      auswählen. Nach der Auswahl werden die Allgemeinen Geschäftsbedingungen
      angezeigt und müssen mittels Aktivierung durch den Kunden bestätigt
      werden. Darunter wird dem Kunden eine Übersicht der von ihm ausgewählten
      Produkte angezeigt. Vor verbindlicher Abgabe der Bestellung kann der Kunde
      seine Eingaben laufend über die üblichen Tastatur- und Mausfunktionen
      korrigieren. Hierzu kann die Browserfunktion „Zurück“ gewählt werden, um
      erneut in den Warenkorb zu gelangen, wo die ausgewählten Waren sowie die
      Menge verändert und korrigiert werden kann. Am Ende der Seite klickt der
      Kunde den Button „Jetzt kostenpflichtig bestellen“ oder eine entsprechend
      §312j Abs. 3 BGB eindeutig beschriftete Schaltfläche. Anschließend öffnet
      sich eine Seite, welche die Bestellung des Kunden bestätigt. <br />3.3.
      Die Annahme durch den Verkäufer erfolgt nach Maßgabe des Absatz Ziffer
      3.1. <br />3.4. Der Vertragstext wird ausschließlich in deutscher und
      englischer Sprache zur Verfügung gestellt.
    </p>

    <h4>4. PREISE</h4>

    <p>
      Sofern sich aus der Produktbeschreibung des Verkäufers nichts Anderes
      ergibt, handelt es sich bei den angegebenen Preisen um Gesamtpreise, die
      die gesetzliche Umsatzsteuer enthalten. Gegebenenfalls zusätzlich
      anfallende Liefer- und Versandkosten werden in der jeweiligen
      Produktbeschreibung gesondert angegeben.
    </p>

    <h4>5. WARENLIEFERUNG, VERSANDKOSTEN, RÜCKSENDEKOSTEN</h4>

    <p>
      5.1. Die Lieferung von Tickets erfolgt mittels Bereitstellung des Tickets
      als PDF-Datei zum Ausdrucken per E-Mail und im Kundenbereich des
      Verkäufers. Die Lieferung von Waren erfolgt auf dem Versandweg an die vom
      Kunden angegebene Lieferanschrift, sofern nichts anderes vereinbart ist.
      Bei der Abwicklung der Transaktion ist die in der Bestellabwicklung des
      Verkäufers angegebene E-Mail Adresse bzw. Lieferanschrift maßgeblich.
      <br />5.2. Sendet das Transportunternehmen die versandte Ware an den
      Verkäufer zurück, da eine Zustellung beim Kunden nicht möglich war, trägt
      der Kunde die Kosten für den erfolglosen Versand. Dies gilt nicht, wenn
      der Kunde sein Widerrufsrecht wirksam ausübt, wenn er den Umstand, der zur
      Unmöglichkeit der Zustellung geführt hat, nicht zu vertreten hat oder wenn
      er vorübergehend an der Annahme der angebotenen Leistung verhindert war,
      es sei denn, dass der Verkäufer ihm die Leistung eine angemessene Zeit
      vorher angekündigt hatte. <br />5.3. Die Gefahr des zufälligen Untergangs
      und der zufälligen Verschlechterung der verkauften Ware geht erst mit
      Übergabe der Ware an den Kunden oder an eine empfangsberechtigte Person
      über. Abweichend hiervon geht die Gefahr des zufälligen Untergangs und der
      zufälligen Verschlechterung der verkauften Ware bereits auf den Kunden
      über, sobald der Verkäufer die Sache dem Spediteur, dem Frachtführer oder
      der sonst zur Ausführung der Versendung bestimmten Person oder Anstalt
      ausgeliefert hat, wenn der Kunde den Spediteur, den Frachtführer oder die
      sonst zur Ausführung der Versendung bestimmte Person oder Anstalt mit der
      Ausführung beauftragt und der Verkäufer dem Kunden diese Person oder
      Anstalt zuvor nicht benannt hat. <br />5.4. Der Verkäufer ist
      ausnahmsweise nicht zur Lieferung der bestellten Ware verpflichtet, wenn
      der Verkäufer die Ware ordnungsgemäß bestellt haben, jedoch nicht richtig
      oder rechtzeitig beliefert wurde (kongruentes Deckungsgeschäft).
      Voraussetzung ist, dass der Verkäufer die fehlende Warenverfügbarkeit
      nicht zu vertreten hat und der Kunde über diesen Umstand unverzüglich
      informiert wurde. Zudem darf der Verkäufer nicht das Risiko der
      Beschaffung der bestellten Ware übernommen haben. Bei entsprechender
      Nichtverfügbarkeit der Ware der Verkäufer dem Kunden bereits geleistete
      Zahlungen unverzüglich erstatten. Das Risiko, eine bestellte Ware besorgen
      zu müssen (Beschaffungsrisiko), übernimmt der Verkäufer nicht. Dies gilt
      auch bei der Bestellung von Waren, die nur ihrer Art und ihren Merkmalen
      nach beschrieben sind (Gattungswaren). Der Verkäufer ist nur zur Lieferung
      aus seinem Warenvorrat und der von ihm bei seinen Lieferanten bestellten
      Waren verpflichtet.
    </p>

    <h4>6. EIGENTUMSVORBEHALT</h4>

    <p>
      Der Verkäufer behält sich bis zur vollständigen Bezahlung des geschuldeten
      Kaufpreises das Eigentum an der gelieferten Ware vor.
    </p>

    <h4>7. BEZAHLUNG</h4>

    <p>
      Dem Kunden stehen verschiedene Zahlungsmöglichkeiten zur Verfügung, die im
      Online-Shop des Verkäufers angegeben werden.
    </p>

    <p>
      <strong>7.1. Zahlung per PayPal:</strong> Bei Zahlung mittels einer von
      PayPal angebotenen Zahlungsart erfolgt die Zahlungsabwicklung über den
      Zahlungsdienstleister PayPal (Europe) S.à r.l. et Cie, S.C.A., 22-24
      Boulevard Royal, L-2449 Luxembourg (im Folgenden: "PayPal"), unter Geltung
      der PayPal-Nutzungsbedingungen, einsehbar
      https://www.paypal.com/de/webapps/mpp/ua/useragreement-full oder - falls
      der Kunde nicht über ein PayPal-Konto verfügt, unter Geltung der
      Bedingungen für Zahlungen ohne PayPal-Konto, einsehbar unter
      https://www.paypal.com/de/webapps/mpp/ua/privacywax-full. <br /><strong
        >7.2. Zahlung per Paypal Rechnung:</strong
      >
      Bei Auswahl der Zahlungsart „PayPal Rechnung“ tritt der Verkäufer seine
      Zahlungsforderung an PayPal ab. Vor Annahme der Abtretungserklärung des
      Verkäufers führt PayPal unter Verwendung der übermittelten Kundendaten
      eine Bonitätsprüfung durch. Der Verkäufer behält sich vor, dem Kunden die
      Zahlungsart „PayPal Rechnung“ im Falle eines negativen Prüfungsergebnisses
      zu verweigern. Wird die Zahlungsart „PayPal Rechnung“ von PayPal
      zugelassen, hat der Kunde den Rechnungsbetrag innerhalb von 30 Tagen ab
      Erhalt der Ware an PayPal zu bezahlen, sofern ihm von PayPal kein anderes
      Zahlungsziel vorgegeben wird. Er kann in diesem Fall nur an PayPal mit
      schuldbefreiender Wirkung leisten. Der Verkäufer bleibt jedoch auch im
      Falle der Forderungsabtretung zuständig für allgemeine Kundenanfragen z.
      B. zur Ware, Lieferzeit, Versendung, Retouren, Reklamationen,
      Widerrufserklärungen und -zusendungen oder Gutschriften. Ergänzend gelten
      die Allgemeinen Nutzungsbedingungen für die Nutzung des Rechnungskaufs von
      PayPal, einsehbar unter
      https://www.paypal.com/de/webapps/mpp/ua/pui-terms.. <br /><strong
        >7.3. Zahlung per Paypal Lastschrift:
      </strong>
      Bei Auswahl der Zahlungsart „PayPal Lastschrift“ zieht PayPal den
      Rechnungsbetrag nach Erteilung eines SEPA-Lastschriftmandats, nicht jedoch
      vor Ablauf der Frist für die Vorabinformation im Auftrag des Verkäufers
      vom Bankkonto des Kunden ein. Vorabinformation ("Pre-Notification") ist
      jede Mitteilung (z.B. Rechnung, Police, Vertrag) an den Kunden, die eine
      Belastung mittels SEPA-Lastschrift ankündigt. Wird die Lastschrift mangels
      ausreichender Kontodeckung oder aufgrund der Angabe einer falschen
      Bankverbindung nicht eingelöst oder widerspricht der Kunde der Abbuchung,
      obwohl er hierzu nicht berechtigt ist, hat der Kunde die durch die
      Rückbuchung des jeweiligen Kreditinstituts entstehenden Gebühren zu
      tragen, wenn er dies zu vertreten hat.
      <br /><strong>7.4. Stripe Payments Europe:</strong> Die Abwicklung der
      Zahlungsarten Kreditkartenzahlung, GIROPAY, SOFORTÜBERWEISNG und SEPA
      LASTSCHRIFT erfolgt in Zusammenarbeit mit der Stripe Payments Europe,
      Ltd., c/o A&l Goodbody, Ifsc, North Wall Quay, Dublin 1, Ireland (im
      Folgenden: “Stripe”), unter Geltung der Stripe-Nutzungsbedingungen,
      einsehbar unter https://stripe.com/de/terms, an die der Verkäufer seine
      Zahlungsforderung abtritt. Stripe zieht den Rechnungsbetrag vom
      angegebenen Kreditkartenkonto oder Bankkonto des Kunden ein. Im Falle der
      Abtretung kann nur an Stripe mit schuldbefreiender Wirkung geleistet
      werden. Die Belastung der Kreditkarte erfolgt umgehend nach Absendung der
      Kundenbestellung im Online-Shop bzw. 2 Tage per SEPA Lastschrift. Der
      Verkäufer bleibt auch bei Auswahl der Zahlungsart Kreditkartenzahlung,
      SEPA Lastschrift, Giropay oder SOFORTÜBERWEISUNG über Stripe zuständig für
      allgemeine Kundenanfragen z.B. zur Ware, Lieferzeit, Versendung, Retouren,
      Reklamationen, Widerrufserklärungen und -zusendungen oder Gutschriften.<br /><strong
        >7.5. Klarna PayNow Lastschrift:</strong
      >
      Nach erfolgreicher Adress- und Bonitätsprüfung im Rahmen des
      Bestellprozesses erteilen Sie mit Abgabe der Bestellung dem
      Online-Anbieter Klarna ein unter SEPA-Lastschriftmandat. Über das Datum
      der Kontobelastung wird Sie Klarna informieren (sog. Prenotification). Mit
      Einreichung des Lastschriftmandats fordert Klarna Ihre Bank zur Einleitung
      der Zahlungstransaktion auf. Die Zahlungstransaktion wird automatisch
      durchgeführt und Ihr Konto belastet. Die Kontobelastung erfolgt nach
      Versand der Ware. Für die Zahlungsabwicklung über Klarna gelten –
      ergänzend zu unseren AGB – die AGB und die Datenschutzerklärung von
      Klarna. Weitere Informationen erhalten Sie im Bestellprozess.
    </p>

    <h4>8. WIDERRUFSRECHT</h4>

    <p>
      <strong
        >8.1 Widerrufsrecht: <br />
        Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen
        diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage
        ab dem Tag des Vertragsabschlusses. Um Ihr Widerrufsrecht auszuüben,
        müssen Sie uns</strong
      >
    </p>

    <p>
      <strong>
        WHOLE GmbH <br />
        Schönhauser Allee 167a <br />
        10435 Berlin <br />
      </strong>
    </p>

    <p>
      <strong>
        Tel. +49(0)30 – +49 30 83217850 <br />
        Fax +49(0)30 – __________ <br />
        contact@wholefestival.com <br />
      </strong>
    </p>

    <p>
      <strong>
        mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter
        Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu
        widerrufen, informieren. Sie können das Muster-Widerrufsformular oder
        eine andere eindeutige Erklärung auch auf unserer Webseite unter
        elektronisch ausfüllen und übermitteln. Machen Sie von dieser
        Möglichkeit Gebrauch, so werden wir Ihnen unverzüglich (z.B. per E-Mail)
        eine Bestätigung über den Eingang eines solchen Widerrufs übermitteln.
      </strong>
    </p>

    <p>
      <strong>
        Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung
        über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist
        absenden.
      </strong>
    </p>

    <p><strong>Folgen des Widerrufs:</strong></p>

    <p>
      <strong
        >Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die
        wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit
        Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine
        andere Art der Lieferung als die von uns angebotene, günstigste
        Standardlieferung gewählt haben), unverzüglich und spätestens binnen
        vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
        Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese
        Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der
        ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
        wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen
        wegen dieser Rückzahlung Entgelte berechnet. Wir können die Rückzahlung
        verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie
        den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je
        nachdem, welches der frühere Zeitpunkt ist.</strong
      >
    </p>

    <p>
      <strong
        >Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen
        vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses
        Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die
        Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn
        Tagen absenden.</strong
      >
    </p>

    <p>
      <strong
        >Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn
        dieser Wertverlust auf einen zur Prüfung der Beschaffenheit,
        Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit
        ihnen zurückzuführen ist.</strong
      >
    </p>

    <p><strong>- Ende der Widerrufsbelehrung-</strong></p>

    <p>
      8.2. Das Widerrufsrecht besteht nicht <br />8.2.1. Bei der Lieferung von
      Waren, die schnell verderben können oder deren Verfallsdatum schnell
      überschritten würde, <br />8.2.2. bei der Lieferung versiegelter Waren,
      die aus Gründen des Gesundheitsschutzes oder der Hygiene nicht zur
      Rückgabe geeignet sind, wenn ihre Versiegelung nach der Lieferung entfernt
      wurde, 8.3. Bitte rufen Sie vor Rücksendung unter der auf unserer
      Internetseite angegebenen Rufnummer bei uns an, um die Rücksendung
      anzukündigen. Auf diese Weise ermöglichen Sie uns eine schnellstmögliche
      Zuordnung der Produkte. <br />8.4. Bitte beachten Sie, dass die in den
      vorstehenden Absätzen genannten Modalitäten nicht Voraussetzung für die
      wirksame Ausübung des Widerrufsrechts sind.
    </p>

    <h4>9. GEWÄHRLEISTUNG UND HAFTUNG</h4>

    <p>
      9.1. Ist die Kaufsache mangelhaft, gelten die Vorschriften der
      gesetzlichen Mängelhaftung. <br />9.2. Die Verjährungsfrist beträgt zwei
      Jahre ab Ablieferung der Ware an den Kunden. <br />9.3.
      Mängelhaftungsfälle sind unmittelbar mit dem Verkäufer abzuwickeln.
      Verhandlungen mit unabhängigen Vertretern, die nicht unmittelbar bei uns
      beschäftigt sind, stellen keine Verhandlungen im Sinne des § 203 Abs.1 BGB
      dar. <br />9.4. Sollte der Kunde Transportschäden an der gelieferten
      Ware/Verpackung bereits bei Lieferung erkennen, so wird der Kunde gebeten,
      sich diese bei der Annahme der Ware von dem Mitarbeiter des
      Transportunternehmens schriftlich bestätigen zu lassen und den Verkäufer
      hierüber zu informieren. Die Versäumung einer schriftlichen Bestätigung
      hat für die gesetzlichen Gewährleistungsrechte des Kunden keine
      Konsequenzen. Durch die schriftliche Bestätigung des Frachtführers wird
      lediglich die Geltendmachung des Anspruchs des Verkäufers gegenüber dem
      Frachtführer bzw. Transportversicherung unterstützt. Sollte der Kunde von
      der Beschädigung Fotografien anfertigen, bitten wir den Kunden diese
      Bilder dem Verkäufer zu übersenden. <br />9.5. Der Verkäufer haftet nach
      den gesetzlichen Bestimmungen, sofern der Kunde Schadensersatzansprüche
      geltend macht, die auf Vorsatz oder grober Fahrlässigkeit, einschließlich
      Vorsatz oder grober Fahrlässigkeit der gesetzlichen Vertreter des
      Verkäufers oder dessen Erfüllungsgehilfen beruhen. Soweit den Verkäufer
      keine vorsätzliche oder grob fahrlässige Vertragsverletzung angelastet
      wird, ist die Schadensersatzhaftung auf den vorhersehbaren, typischerweise
      eintretenden Schaden begrenzt. Für die Verletzung von
      Nebenleistungspflichten mit Ausnahme von vorvertraglichen
      Nebenleistungspflichten haftet der Verkäufer nicht bei leichter
      Fahrlässigkeit. Im Übrigen ist eine Haftung des Verkäufers ausgeschlossen.
      <br />9.6. Die Haftung wegen schuldhafter Verletzung des Lebens, des
      Körpers oder der Gesundheit bleibt unberührt; dies gilt auch für die
      zwingende Haftung nach dem Produkthaftungsgesetz und anderen zwingenden
      gesetzlichen Vorschriften.
    </p>

    <h3>10. VERANSTALTUNG</h3>

    <p>
      10.1. Mit Vorlage des für die Veranstaltung gültigen Tickets erhält der
      Kunde die Möglichkeit an der von dem Verkäufer ausgerichteten
      Veranstaltung teilzunehmen. Jedes Ticket ist mit einem QR Code versehen,
      welcher einmalig gültig ist und einmaligen Zugang zum
      Veranstaltungsgelände berechtigt. 10.2. Dem Kunden bzw. dem Karteninhaber
      ist es untersagt, gefährliche Gegenstände wie z.B. Waffen jeder Art,
      Gegenstände, die sich als Wurfgeschosse verwenden lassen (insbesondere
      Flaschen und Dosen), Gasbehälter, pyrotechnische Artikel (z.B. Fackeln,
      Feuerwerkskörper oder Wunderkerzen), Rucksäcke und große Taschen mit einer
      Größe von mehr als 21 x 30 cm, Regenschirme zur Veranstaltung
      mitzubringen. Verstößt der Kunde gegen diese Vereinbarung, ist der
      Verkäufer als Veranstalterin berechtigt, dem Kunden die Teilnahme an der
      Veranstaltung zu untersagen und ihn des <br />Geländes zu verweisen.
      Etwaige Ansprüche stehen dem Kunden gegenüber dem Verkäufer in diesem
      Falle nicht zu. <br />10.3. Im Falle von Verlegungen von Veranstaltungen
      ist der Verkäufer berechtigt, die Gültigkeit der ursprünglichen Tickets
      der verlegten Veranstaltung für den neuen, verlegten Termin der
      Veranstaltung zu erklären. Eine Rückgabe der Tickets beim Veranstalter
      oder eine Rückabwicklung des Ticketkaufs infolge der Verlegung ist in
      diesen Fällen nicht möglich, es sei denn, die Wahrnehmung des verlegten
      Termins ist für den Ticketinhaber nachweislich nicht zumutbar. Dies gilt
      nicht, sofern der Veranstalter die Verlegung der Veranstaltung zu
      vertreten hat. <br />10.4. Der Verkäufer ist berechtigt, selbst und/oder
      über Dritte im Rahmen der Veranstaltungen Ton-/ Foto- und Filmaufnahmen
      jeglicher Art und Weise (z.B. Rundfunk-/ Fernseh-/Streaming-Aufnahmen
      etc.), insbesondere auch solche, die den Kunden erkennbar und einzeln
      darstellen, herzustellen und diese zeitlich, örtlich und inhaltlich
      unbeschränkt selbst und/oder über Dritte in jeder Form auszuwerten,
      insbesondere (aber nicht abschließend) die Aufnahmen selbst und/oder über
      Dritte zu vervielfältigen, zu verbreiten, öffentlich zugänglich zu machen,
      zu senden etc.. Die Einwilligung erfolgt ausdrücklich unter Verzicht auf
      einen Vergütungsanspruch.
    </p>

    <h3>11. SCHLUSSBESTIMMUNGEN</h3>

    <p>
      11.1. Ergänzungen oder Änderungen dieses Vertrages bedürfen der
      Schriftform. Dies gilt auch für diese Schriftformklausel. <br />11.2.
      Erfüllungsort und Gerichtsstand ist, soweit gesetzlich zulässig, für
      Leistungen sowie sämtliche zwischen den Parteien sich ergebende
      Streitigkeiten, der Sitz des Verkäufers. Bei Endverbrauchern sind
      Erfüllungsort und Gerichtsstand der Sitz des Käufers. Hat der private
      Endverbraucher keinen Wohnsitz innerhalb der Europäischen Union, so ist
      der Gerichtsstand am Sitz des Verkäufers. Im Verkehr mit Endverbrauchern
      innerhalb der Europäischen Union kann auch das Recht am Wohnsitz des
      Endverbrauchers anwendbar sein, sofern es sich zwingend um
      verbraucherrechtliche Bestimmungen handelt. <br />11.3. Für Verbraucher
      und für sonstige Personen mit gewöhnlichem Sitz oder Aufenthalt in einem
      Mitgliedstaat der Europäischen Union (EU) oder des Europäischen
      Wirtschaftsraums (EWR) zum Zeitpunkt des Vertragsschlusses gilt deutsches
      Recht unter Ausschluss des UN-Kaufrechts. Für Verbraucher mit gewöhnlichem
      Aufenthalt in einem Staat, der weder der EU noch dem EWR angehört, findet
      deutsches Recht unter Ausschluss des UN-Kaufrechts sowie des deutschen
      Verbraucherschutzrechts Anwendung. Unabhängig von dieser Rechtswahl gilt
      für Verbraucher innerhalb der EU stets auch das zwingende
      Verbraucherschutzrecht des Staates, in dem sie zum Zeitpunkt des
      Vertragsschlusses ihren gewöhnlichen Aufenthalt haben. <br />11.4. Soweit
      eine oder mehrere der Klauseln dieses Vertrages unwirksam sind oder im
      Laufe der Zeit werden sollten, bleibt die Wirksamkeit des übrigen
      Vertrages davon unberührt. An die Stelle der unwirksamen Klauseln treten
      die gesetzlichen Regelungen. Die gesetzlichen Regelungen gelten auch im
      Falle einer Regelungslücke.
    </p>

    <h4>12. ALTERNATIVE STREITBEILEGUNG</h4>

    <p>
      12.1. Die EU-Kommission stellt im Internet unter folgendem Link eine
      Plattform zur Online-Streitbeilegung bereit:
      http://ec.europa.eu/consumers/odr. Diese Plattform dient als Anlaufstelle
      zur außergerichtlichen Beilegung von Streitigkeiten aus Online-Kauf- oder
      Dienstleistungsverträgen, an denen ein Verbraucher beteiligt ist.
      <br />12.2. Der Verkäufer ist zur Teilnahme an einem
      Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder
      verpflichtet noch bereit.
    </p>
  </div>
</template>

<script>
export default {
  name: "LegalNotice",
};
</script>

<style lang="scss" scoped>
.data-protection-container {
  h1 {
    margin: 25px 0px;
  }

  h3 {
    margin-bottom: 25px;
  }

  h4 {
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 15px;
  }
}
</style>