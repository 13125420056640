<template>
  <div class="container">
    <div class="row col-md-12">
      <h1>Community</h1>
    </div>

    <div class="row simple_discussion">
      <div class="col-md-3 mb-3">
        <div class="card card-body">
          <a class="btn btn-outline-primary btn-block" href="/forum/threads/new"
            >Ask A Question</a
          >
          <hr />

          <div class="forum-thread-filters">
            <h5>
              <strong> Filters </strong>
            </h5>
            <div>
              <a href="/forum/threads">
                <i class="fa-fw fas fa-bars"></i>
                All Threads
              </a>
            </div>
            <div>
              <a href="/forum/threads/answered">
                <i class="fa-fw fas fa-check"></i>
                Answered
              </a>
            </div>
            <div>
              <a href="/forum/threads/unanswered">
                <i class="fa-fw fas fa-question"></i>
                Unanswered
              </a>
            </div>
          </div>

          <hr />

          <div class="forum-thread-filters">
            <h6>
              <strong> By Category </strong>
            </h6>
            <div>
              <a href="/forum/threads"
                ><i class="fa-fw fas fa-circle"></i> All</a
              >
            </div>
            <div>
              <a href="/forum/threads/category/feedback">
                <i style="color: #16bc9c" class="fa-fw fas fa-circle"></i>
                Feedback
              </a>
            </div>
            <div>
              <a href="/forum/threads/category/general">
                <i style="color: #4ea1d3" class="fa-fw fas fa-circle"></i>
                General
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-9 mb-3">
        <div class="card card-body">
          <div>
            No results found for your search. Check out
            <a href="/forum/threads">the latest questions</a> instead?
          </div>

          <div class="forum-thread" v-for="thread in threads" :key="thread.id">
            <div class="row">
              <div class="col">
                <h4>
                  <a
                    href="/forum/threads/asdasd-ef4e3b33-3f56-4d8d-804a-843c7c5e45d1"
                  >
                    {{thread.title}}
                  </a>
                </h4>

                <div class="thread-details">
                  <strong
                    ><a
                      style="color: #16bc9c"
                      href="/forum/threads/category/feedback"
                      >Feedback</a
                    ></strong
                  >
                  • Asked 10 minutes ago by {{thread.user.name}}
                </div>

                <p class="text-muted">{{thread.body}}</p>
              </div>

              <div class="col-sm-2 text-center">
                <a
                  class="thread-posts-count"
                  href="/forum/threads/asdasd-ef4e3b33-3f56-4d8d-804a-843c7c5e45d1"
                >
                  <span class="count">{{thread.forum_posts_count}}</span>
                  <small>post</small>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="whole-footer text-center">© 2021 WHOLE Festival GmbH</div>
  </div>
</template>

<script>
import ForumService from "@/services/forum.service";

export default {
  name: "Forum",
  data: function () {
    return {
      threads: [],
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      const response = await ForumService.getAll();

      console.log(response.data);

      this.threads = response.data;
    },
  },
};
</script>

<style lang="scss">
.card {
  background: var(--whole-bg-lighter);
}
</style>