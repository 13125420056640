import instance from './base.service';

class ForumService {
  getAll() {
    return instance.get(`forum/threads.json`)
  }

  create(params) {
    return instance.post(`forum/threads.json`, params)
  }

  verifyMaxProductsSold(products) {
    return instance.post(`api/products/verify_max_product_sold.json`, {
      products
    });
  }

  // presign() {
  //   return instance.get(`api/uploader/sign.json`);
  // }

  // getStats(slug, id) {
  //   return instance.get(`api/sites/${slug}/links_stats.json?site_link_id=${id}`)
  // }

  // update(slug, params) {
  //   return instance.post(`api/sites/${slug}/save_links.json`, params)
  // }
}

export default new ForumService();
