import instance from './base.service';
import axios from 'axios';

const API_URL = window.location.href.indexOf('wholefestival.locomotiva.info') > 0 ? 'https://whole.locomotiva.info/' : 'http://localhost:3000/';
//const API_URL = 'https://whole.locomotiva.info/' ;

class OrderService {
  getAll() {
    return instance.get(`api/orders.json`)
  }

  create(params) {
    return instance.post(`api/orders.json`, params)
  }

  updateOrderItem(params) {
    return instance.post(`api/orders/update_order_item.json`, params)
  }

  order(params) {
    return axios({
      url: API_URL + 'api/orders/order.pdf?order_id='+ params.id ,
      method: 'GET',
      responseType: 'blob',
    })
  }

  ticket_pdf(params) {
    return instance.get(`api/orders/ticket_pdf.json?order_item_id=${params.id}`)
  }

  send_ticket(params) {
    return instance.post(`api/orders/send_ticket.json`, {order_item_id: params.id})
  }

  cancel(params) {
    return instance.post(`api/orders/cancel.json`, {order_id: params.id})
  }

  paypalCreate(orderId) {
    return instance.get(`api/orders/paypal_create.json?order_id=${orderId}`)
  }

  paypalCapture(orderId) {
    return instance.get(`api/orders/paypal_capture.json?order_id=${orderId}`)
  }

  stripeCreate(orderId) {
    return instance.get(`api/orders/stripe_create.json?order_id=${orderId}`)
  }

  stripeCapture(stripeId) {
    return instance.get(`api/orders/stripe_capture.json?stripe_id=${stripeId}`)
  }

  klarnaCreate(orderId) {
    return instance.get(`api/orders/klarna_create.json?order_id=${orderId}`)
  }

  klarnaCapture(clientToken, params) {
    return instance.post(`api/orders/klarna_capture.json`, { client_token: clientToken, ...params })
  }

  validateCoupon(code, product_id) {
    return instance.get(`api/orders/validate_coupon.json?code=${code}&product_id=${product_id}`)
  }
}

export default new OrderService();
